import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-kz-stars',
  templateUrl: './kz-stars.component.html',
  styleUrl: './kz-stars.component.scss',
  standalone: true,
  imports: [MatIconModule, TranslateModule, NgClass]
})
export class KzStarsComponent {
  private _stars = 0;
  @Input() public set stars(stars: number | string | undefined | null) {
    if (stars === undefined || stars === null) stars = 0;
    this._stars = typeof stars === 'number' ? stars : parseInt(stars);
    //if (this._stars > this.maxStars) this._stars = this.maxStars;
  }
  public get stars() {
    return this._stars;
  }
  get textColorClass() {
    return 'text-kz-' + this.color;
  }
  @Input() color = 'primary';
  @Input() maxStars = 5;
  @Input() hideEmptyStars = false;

  get emptyIcon() {
    return 'empty-star';
  }
  get fillIcon() {
    return 'star-fill';
  }
  public get activedStars() {
    return Array.from(Array(this._stars).keys());
  }
  public get emptyStars() {
    return Array.from(Array(this.maxStars - this._stars).keys());
  }
}
