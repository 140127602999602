import { Pipe, PipeTransform } from '@angular/core';
import { snakeCase } from 'lodash-es';

@Pipe({
  name: 'snakeCase',
  standalone: true
})
export class SnakeCasePipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) return '';
    return snakeCase(value as string);
  }
}
