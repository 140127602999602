<div class="star-info-block">
  @if (stars !== 6) {
    <div class="star-info-container">
      <mat-icon class="star" [color]="color" [svgIcon]="fillIcon" />
      <div class="stars-count">{{ stars }}</div>
    </div>
  }
  @if (stars === 6) {
    <div class="font-bold" [ngClass]="textColorClass">
      {{ 'Apartment' | translate }}
    </div>
  }
</div>
